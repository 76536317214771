.h-55 {
  height: 55% !important;
}
.h-60 {
  height: 60% !important;
}
.h-65 {
  height: 65% !important;
}
.h-70 {
  height: 70% !important;
}
.h-80 {
  height: 80% !important;
}
.h-85 {
  height: 85% !important;
}
.h-90 {
  height: 90% !important;
}
.h-92 {
  height: 92% !important;
}
.h-93 {
  height: 93% !important;
}
.h-95 {
  height: 95% !important;
}

.h-fit-content {
  height: fit-content !important;
}

// widths
.w-5 {
  width: 5% !important;
}
.w-7 {
  width: 7% !important;
}
.w-8 {
  width: 8% !important;
}
.w-10 {
  width: 10% !important;
}
.w-11 {
  width: 11% !important;
}
.w-12 {
  width: 12% !important;
}
.w-13 {
  width: 13% !important;
}
.w-14 {
  width: 14% !important;
}
.w-15 {
  width: 15% !important;
}
.w-16 {
  width: 16% !important;
}
.w-17 {
  width: 17% !important;
}
.w-18 {
  width: 18% !important;
}
.w-19 {
  width: 19% !important;
}
.w-20 {
  width: 20% !important;
}
.w-21 {
  width: 21% !important;
}
.w-22 {
  width: 22% !important;
}
.w-23 {
  width: 23% !important;
}
.w-24 {
  width: 24% !important;
}
.w-25 {
  width: 25% !important;
}
.w-27 {
  width: 27% !important;
}
.w-30 {
  width: 30% !important;
}
.w-33 {
  width: 33% !important;
}
.w-34 {
  width: 34% !important;
}
.w-35 {
  width: 35% !important;
}
.w-40 {
  width: 40% !important;
}
.w-45 {
  width: 45% !important;
}
.w-47 {
  width: 47% !important;
}
.w-48 {
  width: 48% !important;
}
.w-52 {
  width: 52% !important;
}
.w-53 {
  width: 53% !important;
}
.w-55 {
  width: 55% !important;
}
.w-56 {
  width: 56% !important;
}
.w-57 {
  width: 57% !important;
}
.w-58 {
  width: 58% !important;
}
.w-59 {
  width: 59% !important;
}
.w-60 {
  width: 60% !important;
}
.w-65 {
  width: 65% !important;
}
.w-66 {
  width: 66% !important;
}
.w-70 {
  width: 70% !important;
}
.w-80 {
  width: 80% !important;
}
.w-85 {
  width: 85% !important;
}
.w-90 {
  width: 90% !important;
}
.w-95 {
  width: 95% !important;
}