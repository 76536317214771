@media only screen and (max-width: 850px) {

  .accordion {
    border-bottom: none;

    &-header {
      margin-bottom: 0.5rem;

      .accordion-button {
        display: flex;
        flex-direction: row;
        height: 3rem;
        min-height: 3rem;
      }
    }
  }

  .navbar,
  .navbar-dark,
  .bg-dark {
    padding: 1rem 0rem;
    margin-bottom: 2rem;

    .container-fluid {
      padding-left: 1rem;
      padding-right: 1rem;
    }

    .logo {
      width: 12rem;
    }

    .nav-item {
      width: auto;
    }

    .navbar-toggler {
      margin-left: 8px;
      padding: 10px 0px;
      border: none;
      border-radius: 3px;

      svg {
        fill: $white;
      }
    }

    .navbar-toggler:focus {
      border: 1px solid $light;
      box-shadow: none;
    }

    .navbar-toggler:focus-visible {
      border: 1px solid $light;
      outline: none;
    }

    .navbar-collapse {
      position: absolute;
      width: 100%;
      z-index: 3;
      margin-top: 3.5rem;

      .menu-container {
        position: absolute;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-left: -1rem;
        background-color: rgb(16 4 26 / 85%);
        z-index: 4;
        width: 100%;
        height: 60rem;
        transition: height 5s linear;

        .menu {
          display: flex;
          flex-direction: column;
          background-color: #3f3f47;
          width: 100%;
          z-index: 4;

          a {
            display: flex;
            justify-content: center;
          }
        }

        .nav-item {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 6.5rem;
          background-color: $background;
          margin-bottom: 0.3px;
          width: 100%;

          &.dropdown {
            display: flex;
            flex-direction: column;
            padding: 0rem;
          }
          &.show.dropdown {
            height: auto;
            margin-bottom: 0px;

            .dropdown-toggle {
              height: 4.5rem;
            }
          }

          .dropdown-toggle.show {
            border-bottom: 1px solid #3f3f47;
            border-radius: 0px;
          }

          .dropdown-menu {
            height: 8rem;
            padding: 0rem 1rem;
            margin-top: 1px;
            width: 100%;
            border-bottom: none;
            filter: brightness(1.2);

            .dropdown-item {
              display: flex;
              align-items: center;
              height: 4rem;
              // border-bottom: 0.5px solid #494866;
            }
          }
        }

        .nav-item:hover {
          background-color: rgb(29 29 60 / 92%);
          filter: none;
        }

        .last-item {
          margin-bottom: 0px;
        }
      }
    }

    .collapsing {
      transition: none;

      .menu-container {
        transition: height 5s linear;
      }
    }

    .menu-container {
      .dropdown-item {
        background-color: $background;
      }
    }

    .connect {
      height: 2.7rem;

      [data-testid="rk-connect-button"] {
        height: 2.7rem;
      }

      [data-testid="rk-account-button"] {
        height: 2.7rem;
      }

      .ju367v8 {
        font-size: 18px;
        padding: 2px;
      }

      .ju367v85 {
        padding-top: 10px;
      }

      .ju367v6e {
        padding-bottom: 10px;
        padding-left: 12px;
        padding-right: 12px;
      }
    }

    .menu:has(.nav-item.show) {
      .nav-link {
        filter: blur(0.3px);
        color: $light;
      }

      .nav-btn-language {
        filter: blur(0.3px);
        color: $light;

        svg {
          fill: $light;
        }
      }
    }

    .nav-item.show {
      filter: brightness(1.1);
    }
  }

  .banner {
    margin-top: -1rem;
  }

  .oracle-prices {
    display: flex;
    flex-direction: row;
    margin-bottom: 2rem;

    &-item {
      display: flex;
      flex-direction: row;
      margin-right: 2rem;
    }
  }

  .mobile-trade {
    margin-top: 2rem;
    padding: 0rem 1rem;
  }

  .trade-v2 {
    .trade-top {
      flex-direction: column;
    }
    .trade-left, .trade-right {
      width: 100%;
    }
    .trade-left {
      padding: 0rem 3rem;  
    }
    .tradingview-widget-container {
      height: 35rem;  
    }
  }

  .language-dropdown { 
    .dropdown-toggle {
      padding-top: 6.5px;
      padding-bottom: 6.5px;
      margin-left: 7px;
      // margin-right: 7px;
    }
  }

  .exchange {
    .leverage-group {
      margin-top: 2rem;
      .leverage-header {
        align-items: center;
      }
    }
  }

  .chart-wrapper {
    height: 60vh;
  }

  .index-vaults {
    margin-top: 1.2rem !important;
    padding: 0.1rem 0.5rem !important;

    .index-prices-container {
      flex-direction: column;
      padding: 0px 1rem !important;

      h6 {
        font-size: 0.9rem;
      }
      .border-box {
        justify-content: space-between;
      }
    }
    .index-vault-form {
      flex-direction: column;
    }
    .index-form {
      width: 100% !important;
    }

    .vaults-list, .vaults-menu {
      padding: 0rem !important;

      .index-vault {
        margin: 0.5rem 0rem;
        padding: 0rem 0rem !important;
        border: 0.5px solid $light;
        border-radius: 5px;
      }

      .index-vault-mobile {
        &-header {
          padding: 0.9rem 0.5rem;
          border-bottom: 0.5px solid $light;
        }
        &-body {
          display: grid;
          padding: 0.5rem 0.5rem;
          grid-template-columns: 50% 50%;
          gap: 0.2rem;
          row-gap: 0.5rem;
          width: 100% !important;

          .token-icon {
            width: 20px;
            height: 20px;
          }

          .vault-item {
            flex-direction: column;
            justify-content: center !important;
            align-items: flex-start !important;
            width: 97% !important;
            padding: 13px 10px;
            border-radius: 5px;
            background-color: #161617;
            
            .vault-item-inner {
              display: flex;
              flex-direction: column;
              align-items: flex-start;
            }
            .vault-item-value {
              display: flex;
              flex-direction: row;
              justify-content: flex-start !important;
              margin-bottom: 3px;
            }
          }

          .vault-item {
            &.right {
              align-items: flex-end !important;

              .vault-item-inner {
                align-items: flex-end !important;
              }
              .vault-item-value {
                justify-content: flex-end !important;
              }
            }
          }
        }
        &-footer {
          display: grid;
          grid-template-columns: 50% 50%;
          gap: 0.5rem;
          padding: 0.7rem 1rem;
          border-top: 0.5px solid $light;
          width: 100% !important;

          .btn {
            width: 100% !important;
          }
        }
      }
    }

    .vaults-menu {
      width: 100% !important;

      .index-vault-mobile {
        &-footer {
          grid-template-columns: 100%;
        }
      }
    }
  }

  .crypdex {
    background-image: none;
    margin-top: 1rem !important;

    .crypdex-form {
      flex-direction: column;
    }
    .crypdex-form-mint { 
      width: 30rem !important;
    }
  }

  .leverage-input-container {
    .leverage-input {
      font-size: 18px;
      width: 3rem;
    }

    span {
      font-size: 18px;
    }
  }

  .positions {
    padding: 0rem 1rem;
    margin-top: 4rem;
    margin-bottom: 1rem;
    padding-bottom: 3rem;
    background-color: transparent;
    border: none;

    .nav-tabs {
      .nav-item {
        width: 50%;
        .nav-link {
          width: 100%;
        }
      }
    }

    .position-box, .action-box {
      margin-top: 1.2rem;
      margin-bottom: 1rem;
      border: 0.5px solid $lighter;
      border-radius: 5px;
      background-color: $background;

      .box-header {
        padding: 1rem;
        border-bottom: 0.5px solid $lighter;

        .market {
          display: flex;
          flex-direction: row;
          align-items: center;

          &-info {
            margin-left: 10px;

            .postion-direction {
              display: flex;
              flex-direction: row;
              align-items: center;
            }
            .status {
              display: flex;
              flex-direction: row;
              align-items: center;
              margin-left: 7px;
              .text-red, 
              .text-green, 
              .text-grey,
              .spinner-border {
                margin-left: 7px;
              }
            }
          }
        }
      }

      .box-body {
        padding: 1rem;

        /* .position-item {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          margin: 0.5rem 0rem;

          svg {
            margin-top: 0rem;
            margin-right: 5px;
          }
        } */

        .position-item {
          display: grid;
          grid-template-columns: 50% 50%;
          column-gap: 0.5rem;
          margin: 0.5rem 0rem;

          .left,
          .right {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            background-color: #161617;
            padding: 13px 10px;
            border-radius: 5px;
          }

          .right {
            align-items: flex-end;
          }

          .status {
            display: flex;
            flex-direction: row;
            align-items: center;

            .spinner-border {
              margin-right: 5px;
            }
          }

          svg {
            margin-top: 0rem;
            margin-right: 5px;
          }
        }
      }

      .box-footer {
        display: grid;
        grid-template-columns: auto auto;
        column-gap: 0.5rem;
        padding: 1rem 0.8rem;
        border-top: 0.5px solid $lighter;

        &.liquidating {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
        }

        .btn {
          border: 1px solid $purple;
          border-radius: 5px;
          text-decoration: none;

        }
      }
    }

    .action-box {
      .box-footer {
        display: flex;
        flex-direction: row;
        justify-content: center;

        .btn-outline-primary {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          border: 1px solid $purple;
          border-radius: 5px;
          width: 100%;
          padding: 0.375rem 0.75rem;
          text-decoration: none;          
        }
        .btn-outline-primary:hover {
          color: $white;  
        }
      }
    }

    .action-box.hide {
      display: none;
    }

    .history-footer {
      .btn {
        width: 100%;
      }
    }

    .empty-positions {
      display: flex;
      flex-direction: row;
      justify-content: center;
      padding: 1rem;
      border: 1px solid $purple;
      border-radius: 5px;
    }
  }

  .pl-version-chooser {
    .btn {
      padding: 2px 5px;
      font-size: 14px;
      width: 7rem;
    }
  }

  .liquidity {
    padding: 0rem 1rem;

    &.pro {
      padding: 0rem;
      margin-top: 0rem;

      .version-chooser {
        margin-top: -1.5rem;
        margin-bottom: 1rem;
      }
      .pl-version-chooser {
        margin-top: 0em;
      }
    }
  }

  .rewards { 
    padding: 2rem 1rem;
    margin-top: 1rem;

    .reward-info-box {
      margin: 0.5rem;
    }
  }

  .airdrop-banner {
    display: flex;
    margin: 0rem 1rem;

    .left {
      .btn {
        margin-left: 0.2rem;
        margin-top: 0.2rem;
      }
    }
  }

  .referrals-link {
    flex-direction: row;
    align-items: center;

    .invite-link {
      margin-left: 0px;
      width: 30rem;
    }

    .share-links {
      margin-top: 0rem;
      padding-left: 0.5rem;

      .react-share__ShareButton {
        margin: 0rem 3px;
      }
    }
  }

  .referrals {
    display: flex;
    flex-direction: column;
    padding: 2rem;

    &-info {
      width: 100%;
      margin-right: 0rem;
      margin-bottom: 1.5rem;
    }

    &-activity {
      width: 100%;
      margin-left: 0rem;

      .right {
        margin-top: 1rem;

        .rewards {
          padding: 0rem 2rem;
        }
      }
    }
  }

  .referral-create {
    display: flex;
    margin: 0rem 1rem;

    .left {
      .referral-info {
        display: flex;
        flex-direction: column;
        align-items: start;
      }
    }
  }

  .terms-of-use-container {
    margin-top: 1rem;

    .heading {
      margin-bottom: 1rem;
    }
  }
}

@media only screen and (max-width: 600px) {
  .wrapper {
    padding: 0rem;
  }

  [data-rk] {
    ._1ckjpok7 {
      padding-bottom: 0px;
      top: -100px;
    }
  }

  .not-on-mobile {
    display: none;
  }

  .accordion {
    border-top: 0.5px solid $dark;
  }

  .navbar,
  .navbar-dark,
  .bg-dark {
    padding: 0rem;
    margin-top: 0.5rem;
    margin-bottom: 0rem;

    .navbar-brand {
      margin-right: 0.5rem;
    }

    .logo {
      width: auto;
    }

    .navbar-toggler {
      margin-left: 5px;
      padding: 4px 2px;
      margin-bottom: 0.5px;
    }
    .navbar-toggler:focus {
      border: none; 
    }

    .navbar-collapse {
      margin-top: 2.5rem;

      .menu-container {
        height: 120rem;
        margin-left: -1rem;

        .nav-item, .nav-link {
          height: 4.5rem;
          margin-bottom: 0.5px;
        }

        .dropdown-item:last-child {
          border-bottom: 1.5px solid $dark;
        }
      }
    }

    .nav-btn-language {
      font-size: 1.1rem;
    }

    .connect {
      height: 2.05rem;
      
      [data-testid="rk-connect-button"] {
        height: 2.05rem;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
      }

      [data-testid="rk-account-button"] {
        height: 2.05rem;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
      }

      .ju367v8 {
        font-size: 16px;
      }

      .ju367v85 {
        padding-top: 0px;
      }

      .ju367v6e {
        padding-bottom: 0px;
      }
    }

    @media only screen and (max-width: 390px) {
      .container-fluid {
        padding-left: 0.32rem;
        padding-right: 0.32rem;
      }

      .navbar-collapse {
        width: 100%;
        
        .menu-container {
          width: 105%;
        }
      }
    }
  }

  .banner { 
    margin-top: 1rem;  
  }

  .loading-page {
    
    .container-background-video {
      display: none;
    }

    &-container {
      .breathing-icon {
        width: 90%;
      }
    }
  }

  .oracle-prices {
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: auto auto;
    gap: 0.75rem;
    margin-bottom: 2rem;
    padding: 0.7rem;
    border: 0.5px solid $dark;
    border-radius: 5px;
    // background-color: #0f0f10;

    &.no-gap {
      gap: 0rem;
    }

    &-item {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-right: 0rem;
    }
  }

  .version-chooser {
    .overlay-child {
      width: 100%;
    }
  }

  .pl-version-chooser { 
    width: 100%;
    margin-top: 2rem !important;

    .btn {
      padding: 2px 5px;
      font-size: 16px;
      width: 100%;
    }
  }

  .markets {
    &-tabs-container {
      margin-bottom: 1rem;
    }
  }

  .markets-stats {
    display: none;
    .stats-item {
      margin-right: 10px;
      
      &.right {
        margin-right: 0px;
      }
      .number {
        font-size: 12px;
        margin-left: 5px;
      }
    }
  }

  .mobile-trade {
    padding: 0rem 1rem;

    .trade-right {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      .spinner-container.trade {
        padding-top: 7rem;
        padding-bottom: 4rem;
      }
    }

    .markets-tabs-container {
      justify-content: space-between;
      .overlay-child {
        padding-right: 5%;
      }
    }

    .market-bar {
      margin-top: 0rem;
      margin-bottom: 1rem;
      border-bottom: 0.5px solid $dark;
    }

    .market-info {
      display: flex;
      flex-direction: column;
      padding: 0rem 0rem;
      width: 50%;
      /* .btn-market-info:hover {
        background-color: $background
      } */
    }

    .market-metrics {
      flex-grow: 1;
      justify-content: space-between;
      border: none;
    }

    .market-info-wrapper,
    .market-metrics-container {
      position: absolute;
      background-color: $background;
      border: 0.1px solid $dark;
      border-top: none;
      border-radius: 3px;
      -webkit-box-shadow: 0px 3px 3px 3px #8330bd46;
      -moz-box-shadow: 0px 3px 3px 3px #8330bd46;
      box-shadow: 0px 3px 3px 3px #8330bd46; //#aa3ef746
      padding: 1rem 0.7rem;
      height: 35rem;
      width: 92%;
      margin-left: 0rem;
      overflow-y: scroll;
      z-index: 4;

      .info-row {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 1.1rem 0.1rem;
        height: 2rem;
        border-bottom: 0.5px solid $darker;
      }

      a {
        font-size: 0.87rem;
      }
    }

    .market-info-wrapper {
      margin-top: 2.25rem;
    }

    .market-metrics-container {
      left: 1rem;
      margin-top: 2.15rem;
      height: auto;

      .funding-dropdown {
        width: 2.4rem;
        .dropdown-toggle {
          display: flex;
          flex-direction: row;
          align-items: center;
          border: 0.5px solid #494866;
          border-radius: 3px;
          padding: 1px 0.3rem;
          font-size: 0.7rem;
          width: 2.4rem;
        }

        .dropdown-toggle::after {
          font-size: 0.85rem;
          margin-top: 2px;
          color: #9795b0;
        }

        .dropdown-menu {
          width: 16rem;
          margin-left: -1rem;
        }
      }

      .market-metrics-value {
        display: flex;
        flex-direction: row;
      }

      .info-row {
        flex-direction: row-reverse;

        .small {
          font-size: 0.9rem;
        }
      }
    }

    .btn-market-info:hover,
    .btn-market-metrics:hover,
    .btn-market-info:active,
    .btn-market-metrics:active {
      background-color: $background;
    }

    .btn-market-info,
    .btn-market-metrics {
      width: 100%;
      height: 100%;
      border: none;
      border-radius: 0px;
      padding: 0.4rem 1rem;

      &.active {
        background-color: $purple;
      }

      span {
        font-size: 14px;
        color: $lighter !important;
      }

      svg {
        height: 16px;
        width: 16px;
        fill: $lighter;
      }
    }

    .btn-market-info {
      border-right: 0.5px solid $dark;
    }

    .btn-market-metrics {
      border-left: 0.5px solid $dark;
    }

    .btn-restricted {
      width: 100%;
      margin-top: -1rem;
      margin-bottom: 2rem;
      font-size: 1.3rem;
    }

    .new-position {
      width: 100%;
      height: 3rem;
    }

    .exchange {
      margin-top: 4rem;
      width: 100%;
    }

    &.trade-v2 {
      .exchange {
        margin-top: 2rem;
      }
    }
  }

  .positions {
    .filters-container {
      .left {
        width: 100%;
        justify-content: space-between;
      }
      .right {
        display: none;
      }

      .filter-item {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
      }
  
      .dropdown {
        margin-left: 0rem;
        margin-top: 5px;
        width: 100%;

        &-toggle {
          width: 100%;
        }
      }
    }
  }

  .liquidity {
    margin-top: 2rem;
    margin-bottom: 2rem;
    padding: 0rem 1rem;

    &-card {
      max-width: 100%;
      margin-top: 0;
      padding: 0rem;
      border: none;

      &-header {
        .version-chooser {
          margin-top: 0rem !important;
        }
      }

      &-body {
        flex-direction: column;

        .nav-tabs {
          margin-bottom: 0rem !important;
          border: 0.5px solid $light;
          border-top-left-radius: 5px;
          border-top-right-radius: 5px;
          
          .nav-item {
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;
            width: 50%;
          }
          .nav-link {
            padding: 0.75rem 0.15rem;
            width: 100%;
          }
          .nav-link:hover {
            transform: none;
            border-bottom: 2px solid rgba(223, 33, 254, 0.5);
          }  
        }

        .liquidity-provide, .liquidity-rewards {
          padding-top: 0rem;
        }
      }

      .body-left {
        width: 100%;
        margin-right: 0;

        .deposit-button {
          margin-bottom: 1rem;

          &.rewards-v2 {
            padding-top: 0.6rem;
            padding-bottom: 0.6rem;
          }
        }

        &.provide, &.rewards-v2 {
          padding: 0.5rem;
          padding-top: 1.5rem;
          border-left: 0.5px solid $light;
          border-right: 0.5px solid $light;
          border-bottom: 0.5px solid $light;
          border-bottom-left-radius: 5px;
          border-bottom-right-radius: 5px;
          margin-bottom: 2rem;
        }

        .rewards-exit {
          height: 13rem;
          padding: 0rem;
        }
      }

      .body-right {
        height: 20rem;
        width: 100%;
        margin-left: 0;
        padding: 0.5rem;

        &.rewards-v2 {
          padding: 0.7rem;
          height: auto;

          .value-item {
            margin-top: 0rem;
            margin-bottom: 0.5rem;
            padding: 1rem;
            background-color: #161617;
            border-radius: 5px;
          }

          .values-bottom {
            .btn-claim {
              margin-top: 1rem;
              padding-top: 0.7rem;
              padding-bottom: 0.7rem;
            }
          }
        }
      }
    }

    &-provide {
      display: flex;
      flex-direction: column;
    }

    &-rewards {
      display: flex;
      flex-direction: column;
    }
  }

  .crypdex {
    margin-top: 0.5rem !important;
    padding-top: 1rem !important;

    .crypdex-form {
      flex-direction: column;
      width: 100%;
      padding: 1rem;
    }

    .crypdex-form-mint {
      width: 100% !important;
    }
  }

  .incentives {
    padding: 1rem 1rem !important;

    .border-box {
      width: 100% !important;

      ul {
        padding-left: 1rem;
      }
    }

    .incetive-pool {
      flex-direction: column;

      .incetive-value {
        justify-content: space-between !important;
        padding: 8px 7px;
        background-color: #191927;
        border-radius: 5px;
      }
    }
  }

  .rewards {
    padding: 2rem 0.5rem;
    margin-top: 1rem;

    .nav-item {
      width: 50%;
  
      .nav-link {
        width: 100%;
      }
    }

    .reward-seasons {
      margin-bottom: 4rem;

      .seasons-pagination {
        margin: 0rem;
      }
    }

    .reward-info-box, .reward-stat-box {
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-between;
      margin: 0.5rem 0rem;
      padding-left: 1rem; 
      padding-right: 1rem;
      width: 100%;
      height: 4rem;

      &.btn {
        justify-content: center;
      }

      h6 {
        font-size: 1.3rem;
      }
      span {
        font-size: 1.3rem;
      }
    }

    .rewards-card {
      display: grid;
      grid-template-columns: 100%;
      gap: 1rem;
      padding: 1rem 0.5rem;

      .reward-box.right {
        padding: 1rem 0.5rem;
        flex-direction: column-reverse;
      }

      .total-rewards-box {
        margin-bottom: 4rem;
      }
    }
  }

  .notification-box {
    margin: 0.5rem;

    .token-icon {
      display: none;
    }
  }

  .cashback {
    padding: 2rem 1rem 0rem 1rem;

    &-header {
      .subtitle {
        margin: 1rem 0.5rem;
        width: 100%;
      }
    }

    &-user {
      display: grid;
      grid-template-columns: auto;
      grid-template-rows: auto;
      grid-template-columns: 100%;
      gap: 0.5rem;
      border: 0.5px solid $lighter;
      border-radius: 5px;
      padding: 1rem;

      .cashback-box {
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;
        padding: 1.5rem 1rem;
        background-color: #161617;
        margin: 0rem;
        width: 100%;
        height: 3rem;
        border: none;
        
        .box-title {
          h6 {
            font-size: 1.15rem;
          }
        }
        .box-value {
          .text-purple {
            font-size: 1.5rem;
          }
        }
        &.btn {
          display: flex;
          justify-content: center;
          background-color: transparent;
          margin-top: 0.5rem;
          grid-column: auto;
          grid-row: auto;
          height: 2.2rem;
          font-size: 1.3rem;
        }
      }
    }

    &-options {
      margin-left: 0rem;
    }

    &-leaderboard {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 0rem;
      padding-left: 0rem;
      padding-right: 0rem;
      background-color: $background;
      width: 100%;

      .leader-box {
        display: grid;
        grid-template-columns: 50% 49%;
        grid-template-rows: auto auto;
        gap: 0.15rem;
        padding: 0.05rem;
        border: 0.5px solid $lighter;
        border-radius: 5px;
        margin-bottom: 1rem;
        width: 100%;

        &.hide {
          display: none;
        }

        &-row {
          display: flex;
          flex-direction: column-reverse;
          justify-content: center;
          align-items: center;
          padding: 1rem 0.5rem;
          border-radius: 5px;
          
          background-color: #151515;

          .title {
            font-size: 1.1rem;
          }

          .box-value {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            margin-bottom: 0.3rem;
            
            span {
              font-size: 1.2rem;
            }
          }
        }        
      }

      .load-more {
        width: 100%;
        height: 3rem;
        margin-top: 0.5rem;
      }
    }
  }

  .referrals-link {
    display: flex;
    flex-direction: column;
    width: 100%;

    .btn-get-reflink {
      width: 100%;
    }

    .invite-link {
      width: 100%;
      margin-top: 0.5rem;
    }

    .share-links {
      padding-left: 0.5rem;
      margin-top: 0.7rem;
      .react-share__ShareButton {
        margin: 0rem 3px;
      }
    }
  }

  .referrals {
    padding: 1rem 0rem;

    &-activity {
      .left {
        p {
          padding-right: 0rem;
        } 
      }

      .right {
        .rewards {
          display: flex;
          flex-direction: column;
          padding: 0rem 0.5rem;

          .overlay-child {
              width: 100% !important;
            }

          .rewards-box {
            display: flex;
            flex-direction: row-reverse;
            justify-content: space-between;
            width: 100%;
            padding: 0.5rem 1rem;
            height: 3.5rem;

            .box-title {
              display: flex;
              flex-direction: row;
              align-items: center;
              h6 {
                font-size: 1.2rem;
                margin-right: 5px;
              }
            }

            &.btn {
              display: flex;
              flex-direction: row;
              justify-content: center;
              margin-left: 0px;
              margin-right: 0px;
            }
          }
        }
      }
    }
  }

  .referral-accept {
    padding: 1rem;

    .btn-close-container {
      margin-top: -0.5rem;
      margin-right: -1rem;
    }

    .info-container {
      display: flex;
      flex-direction: row;

      .info-box {
        padding: 0.5rem;
        width: 100%;

        .img-container { 
          display: none;
        }
        p {
          width: 100%;
        }
      }
    }

    .btn-container {
      width: 100%;
      .btn {
        width: 100%;
      }
    }
  }

  .referral-create {
    display: flex;
    flex-direction: column-reverse;
    margin-top: 0.5rem;
    padding: 0.5rem;

    .left {
      .img-container {
        display: none;
      }
      
      .referral-info {
        display: flex;
        flex-direction: column;
        align-items: start;
        margin-top: -1rem;

        .info {
          width: 100%;
        }

        @media only screen and (max-width: 390px) {
          margin-top: -0.2rem;
        }
      }
    }

    .right {
      justify-content: flex-end;
    }
  }

  .terms-of-use-container {
    margin-top: 3rem;
    padding: 0rem 1rem;

    .heading {
      margin-bottom: 1.5rem;
    }
  }
}

@media only screen and (max-width: 390px) {
    
  .markets-dropdown {
    .toggle-section.market {
      width: 54% !important;
    }

    &.settokens {
      .toggle-section.market {
        width: 65% !important;
      }
    }
  }

  .mobile-trade { 
    .btn-market-info,
      .btn-market-metrics {
        padding: 0.3rem 0.5rem;
    }
  }

  .positions {
    .position-box,
    .action-box {
      .box-body {
        .position-item {
          .action-date {
            font-size: 0.9rem;
          }
        }
      } 
    }
  }
}