@import "./colors";
@import "./fonts";
@import "./utils";
@import "~bootstrap/scss/bootstrap";

body,
#root,
html,
.wrapper {
  height: 100%;
  background-color: $black;
}

.wrapper {
  padding: 0rem 0rem;
  @media only screen and (min-width: 1401px) and (max-width: 1700px) {
    padding: 0rem 0rem;
  }
  @media only screen and (min-width: 851px) and (max-width: 1400px) {
    padding: 0rem 0rem;
  }
  @media only screen and (min-width: 601px) and (max-width: 850px) {
    padding: 2rem;
  }
}

[data-rk] {
  height: 100%;

  /* svg {
    height: auto;
    width: auto;
  } */

  .ju367v9x {
    background-color: $background;
  }

  ._9pm4ki3 {
    top: -50px;

    @media only screen and (max-width: 600px) {
      top: 0px;
      padding-top: 600px;
    }
  }
}

.only-mobile {
  display: none;

  @media only screen and (max-width: 600px) {
    display: block;
  }
}

.bg-dark {
  background-color: $background !important;
}

.bg-row-dark {
  background-color: #0e0e19;
}

.bg-row-light {
  background-color: #212138d1;
}

.border-primary {
  border: 0.5px solid $darkest !important;
  border-radius: 5px;
}

.border-light {
  border: 0.5px solid #212138d1 !important;
  border-radius: 5px;
}


.logo {
  .cls-1 {
    fill: none !important;
  }
}

h1, h2, h3, h4, h5, h6, ol, ul {
  color: $white;
  margin-bottom: 0px;  
}

li {
  color: $white;
}

h6.margin-left {
  margin-left: 5px;
}
h6.margin-right {
  margin-right: 5px;
}


a {
  color: $purple;
  text-decoration: underline;
  font-weight: 600;
}
a:hover {
  color: $pink;
}

p {
  color: $white;
  margin-bottom: 0rem;
}

.bold {
  font-weight: 600;
}

span {
  color: $lighter;
  &.zoom-2 {
    font-size: 1.2rem;
  }
  &.zoom-1 {
    font-size: 1.1rem;
  }
  &.medium {
    font-size: 0.9rem;
  }
  &.small {
    font-size: 0.8rem;
  }
  &.xs {
    font-size: 0.75rem;
  }
  &.xxs {
    font-size: 11px;
  }
  &.bold {
    font-weight: 600;
  }

  &.separator {
    padding: 0px 5px;
  }

  &.error {
    color: $red-error;
  }
}

.text-purple {
  color: $purple;
}

.text-grey {
  color: $lighter;
}

.text-green {
  color: $green;
}

.text-red {
  color: $red-error;
}

.text-muted {
  color: $light !important;
}

.text-muted-light {
  color: #b6b4cb !important;
}

.text-red-dark {
  color: $red-dark !important;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-dashed, .text-decoration-dotted {
  text-decoration: underline dotted;
}

.text-decoration-muted {
  text-decoration-color: #6f6f77;
}

.flex-row, .hstack {
  display: flex;
  flex-direction: row;
  align-items: center;

  &.center {
    justify-content: center;
  }

  &.space-between {
    justify-content: space-between;
    width: 100%;
  }
}

.flex-column {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.margin-right {
  margin-right: 5px;
}

.info-tag {
  font-size: 11px;
  color: $purple;
  background-color: transparent;
}

.title {
  font-size: 13.5px;
}

.opaque {
  color: $light;
}

.index-vault-tabs {
  .nav-link {
    width: 12rem;
  }
}
.index-vaults {
  .vaults-menu {
    .dropdown-toggle:disabled {
      background-color: var(--bs-btn-disabled-bg);
      opacity: var(--bs-btn-disabled-opacity);
    }
  }
}

.number {
  padding: 0px 5px;

  &.gray {
    color: $light;
  }
  &.small {
    font-size: 13px;
  }
  &.green {
    color: $green;
  }
  &.red {
    color: $red-error;
  }
}

.btn {
  .btn-spinner {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    .spinner-border {
      margin-right: 10px;
    }
  }
}

.btn-primary {
  background-color: $darker;
  border: 1px solid $purple;
  background-color: $background;
  // box-shadow: 0px 0px 39px -6px rgba(164, 64, 242, 0.4);
}

.btn-danger {
  background-color: transparent;
  color: $red-error;
  border-color: $red-error;
}

.btn-outline-danger {
  border: none;
  padding: 0rem;
  background-color: transparent;
  color: $red-error;
}

.btn-outline-primary {
  color: $white;
  text-decoration: underline;
  border: none;
}

.btn-outline-primary:disabled {
  color: $white;
  // text-decoration: none;
  opacity: 0.7;
}

.btn:hover, .btn-check+.btn:hover {
  background-color: $purple;
  border-color: $purple;
  box-shadow: 0px 0px 39px -6px rgba(164, 64, 242, 0.4);
}

.btn-check:checked+.btn,
:not(.btn-check)+.btn:active,
.btn:first-child:active,
.btn.active,
.btn.show {
  background-color: $background;
  border-color: $purple;
  box-shadow: 0px 0px 39px -6px rgba(164, 64, 242, 0.4);
}

.btn-outline-primary:hover {
  border: none;
  background-color: transparent;
  box-shadow: none;
  text-shadow: 0px 4px 13px $purple;
}

.btn-secondary {
  background-color: $background;
  border: 1px solid $highlight;
  border-color: $purple;
}

.btn-secondary:hover {
  background-color: transparent;
  border-color: $light;
}

.btn-secondary.small {
  width: auto;
  background-color: transparent;
  border: none;
  padding: 0rem;
  font-weight: 600;

  &.underline {
    text-decoration: underline;
  }
}

.btn-group {
  width: 100%;

  .btn-primary {
    background-color: $background;
  }
}

.btn-check:checked+.btn,
:not(.btn-check)+.btn:active {
  background-color: $purple;
  border-color: $purple;
}

.btn:disabled {
  cursor: not-allowed;
  border-color: $dark;
}

.btn-secondary:active {
  background-color: $black !important;
}

.btn-copy {
  border: none;
  padding: 0px 5px;
}
.btn-copy:hover {
  background-color: $black;
}

button:disabled {
  cursor: not-allowed;
}

.btn-group-tabs {
  .btn {
    border: none;
    border-radius: 0px;
    border-bottom: 0.5px solid $dark;
  }
  .btn-check:checked+.btn,
  :not(.btn-check)+.btn:active {
    border-bottom: 0.5px solid $purple;
  }
}

.border-black {
  border: 1px solid $background;
}

.border-gray {
  border: 1px solid $dark;
}


.hover-transparent:hover {
  background-color: transparent;
}

.badge {
  &.bg-light {
    background-color: $white !important;
  }
}

.card {
  background-color: $background;
  border: 0.5px solid $darkest;
  border-radius: 5px;
}

.fire {
  animation: fire 2s infinite alternate;
  color: #fff !important;
  font-size: 17px;
  text-shadow: 0 0 4px #7940f2, 0 0 10px #e440f2, 0 0 20px $neon-highlight, 0 -10px 40px #472a85;
  font-weight: 800;
}

.form-control {
  background-color: $background;
  color: $white;
  font-size: 1.3rem;

  &.small {
    border: 0.5px solid #494866;
    border-radius: 3px;
    font-size: 12px;
    padding: 5px;
  }
}

.form-control:focus {
  background-color: $background;
  box-shadow: none;
  color: $white;  
}
.form-control::placeholder {
  color: $light;
  opacity: 0.35;
}
.form-control:disabled {
  background-color: $background;
  color: $light;
}


.input-group > .form-control:focus {
  z-index: 1;
}

.dropdown {
  background-color: $background;

  &-menu {
    border-bottom: 0.7px solid $dark;
    background-color: $background;
    padding: 0rem;    
  }
  &-menu-small {
    width: 8rem;
  }
  &-menu.show {
    width: 100%;
    z-index: 5;
  }
  &-menu-small.show {
    width: 8rem !important;
  }
  &-item {
    color: $white;

    .icons {
      margin-right: 0.5rem;
    }
  }
  &-item:hover, &-item:focus {
    color: $white;
    background-color: $purple; 
  }

  .btn:hover {
    background-color: $background;
  }
}

.dropdown-toggle {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: $background;
  border: 0.5px solid $light;

  &-container {
    display: flex;
    flex-direction: row;
  }
}

.dropdown-toggle::after {
  color: $white;
  font-size: 20px;
}

.dropdown-toggle-small::after {
  color: $white;
  font-size: 18px;
  margin-top: 2px;
}

.dropdown-toggle:disabled {
  background-color: $background;
  color: $white;
  opacity: 1;

  .title {
    color: $white;
  }
}

.nav-tabs {
  border-bottom: none;

  .nav {
    &-item {
      background-color: $background;
      color: $white;
    }
    &-link {
      color: $white;
      border: none;
      font-size: 1.1rem;
      padding: 0.5rem 1.5rem;

      .info-icon {
        margin-top: 0.3rem;
      }
    }
    &-link.active {
      background-color: $background;
      border-bottom: 1px solid $neon-highlight;
      color: $white;
    }
    &-link:hover {
      border-bottom: 1px solid $neon-highlight;
      transform: scale(1.02, 1.01);
      transition: transform 0.8s;
      filter: brightness(1.3);
    }
  }
}

.tab-content {
  background-color: $background;
}

.table, .table-dark {
  --bs-table-color: $white;
  --bs-table-bg: $background;
  --bs-table-border-color: #5b5a75;
  --bs-table-striped-bg: $darker;
  --bs-table-striped-color: #ffffff;
  --bs-table-active-bg: $darker;
  --bs-table-active-color: #ffffff;
  --bs-table-hover-bg: #141018;
  --bs-table-hover-color: #ffffff;
  color: $white;
  border-color: var(--bs-table-border-color);

  th {
    &.center {
      text-align: center;
    }
    &.right {
      text-align: right;
    }    
  }
  
  td {
    vertical-align: middle;
    .market {
      display: flex;
      flex-direction: row;
      align-items: center;
      .market-info {
        display: flex;
        flex-direction: column;

        @media only screen and (max-width: 1370px) { 
          max-width: 8rem;
        }
      }
      svg, .jpegz, .tcap, .pepe, .token-icon {
        margin-right: 7px;
      }
    }

    &.center {
      text-align: center;
    }
    &.right {
      text-align: right;
    }

    .flex-column {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
    }

  }

  .table-totals {
    border-bottom-color: transparent;
    td {
      padding-top: 1rem;
    }
  }
}

.leverage-input-container {
  display: flex;
  flex-direction: row;
  border: 1px solid $dark;
  border-radius: 5px;
  padding: 2px 5px;
  background-color: $black;

  &.error {
    border-color: #ff0e0e;
  }

  .leverage-input {
    font-size: 14px;
    padding: 0px;
    text-align: right;
    border: none;
    width: 5rem;    
  }

  span {
    font-size: 14px;
  }
}

.modal-edit-position2 {
  .modal-content {
    width: 25rem;
  }

  .btn-edit-position {
    width: 100%;
  }

  .leverage-group {
    margin-top: 1rem;

    .leverage-values {
      display: flex;
      justify-content: space-between;
      padding: 0px 3px;
      width: 100%;
    }
  }
}

// app
.header {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 0rem;
  z-index: 2;
  .logo {
    width: 10rem;
    fill: #ffffff !important;
  }
}

.navbar, .navbar-dark, .bg-dark {
  background-color: $background !important;
  padding: 1rem 0.3rem;
  
  // margin-bottom: 0.7rem;
  
  .logo {
    width: 10rem;
    fill: #ffffff !important;
  }

  .nav-link {
    background-color: $background;
    color: $off-white;
    font-size: 1.1rem;
    text-wrap: nowrap;
  }

  .navbar-right {
    display: flex;
    flex-direction: row;
    align-items: center;

    .btn:hover {
      background-color: $background;
      box-shadow: none;
      filter: brightness(1.3);
      transform: scale(1.01);
      transition: transform 0.3s;
    }
    .connect-button:hover {
      background-color: $purple;
      filter: brightness(1);
      transform: scale(1.02);
      transition: transform 0.4s;
    }
  }

  .item-version {
    padding: 0.3rem 1rem;
    border: 1px solid $purple;
    border-radius: 5px;
  }

  .nav-btn-language {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: $background;
    border: none !important;
    border-radius: 0px !important;
    color: $white;
    width: 100%;
    height: 4.5rem;
    margin-left: 0px !important;

    .flag {
      margin-right: 7px;
    }
  }
}

.token-icon {
  &.margin-right {
    margin-right: 7px;
  }
  &.margin-left {
    margin-left: 7px;
  }
}

svg.normal, .jpegz.normal, .tcap.normal, .ctx.normal, .pepe.normal, .token-icon.normal {
  width: 35px;
  height: 35px;
}

svg.medium,
.jpegz.medium,
.tcap.medium,
.ctx.medium,
.pepe.medium,
.token-icon.medium {
  width: 25px;
  height: 25px;
}

svg.small, .jpegz.small, .tcap.small, .ctx.small, .pepe.small, .token-icon.small {
  width: 20px;
  height: 20px;
}

.ctx.small {
  width: 25px;
  height: 25px;
}

svg.xs,
.jpegz.xs,
.tcap.xs,
.ctx.xs, 
.pepe.xs, 
.token-icon.xs
{
  width: 15px;
  height: 15px;
}

.asset-input {
  &-group {
    display: flex;
    flex-direction: column;
    margin-top: 1rem;

    .input-collateral,
    .input-group {
      background-color: $background;
      border: 0.5px solid $light;
      border-radius: 5px;
      padding: 0.5rem;

      &.index {
        height: 2.5rem;
      }
    }

    .form-control {
      position: absolute;
      width: 97%;
      height: 60%;
      border: none;
      padding: 0rem;
      margin-top: -0.4rem;
      z-index: 2;

      &.index {
        margin-top: 0rem;
      }
    }

    .asset-info {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      justify-content: center;
      width: 100%;

      .btn {
        z-index: 1;
      }

      .asset-info2 {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
        padding: 0.1rem;
        width: 40%;
        z-index: 2;
        
        svg {
          margin-right: 7px;
        }
      }
    }

    .index-info {
      display: flex;
      z-index: 2;

      svg, .jpegz, .tcap, .pepe, .token-icon {
        margin-right: 5px;
      }
    }

    .asset-balance {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
      margin-top: 0.62rem;
      width: 75%;

      .title {
        margin-right: 5px;
      }

      .balance {
        margin-right: 10px;
      }
    }

    .dropdown-menu.show {
      width: auto;  
    }
  }

  &-top {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}

.trade-module {
  background-color: #0a0a0a;
  padding: 1rem 0.5rem;
  border: 0.5px solid #281e31;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  height: auto;
  margin-bottom: 1rem;

  &.trade-v2 {
    .version-chooser {
      margin-bottom: 0.5rem;
    }
  }

  .spinner-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
    
  .trade-top {
    display: flex;
    flex-direction: row;
    margin-bottom: 0.5rem;
    min-height: 30rem;
  }

  .trade-left {
    width: 70%;
    margin-right: 0.5rem;

    @media only screen and (max-width: 500px) {
      display: none;
      margin-right: 0rem;
    }
  }

  .trade-right {
    width: 30%;
    margin-left: 0.5rem;

    &.markets-v1 {
      margin-top: -2rem;
    }
    @media only screen and (max-width: 500px) {
      width: 100%;
    }
  }
  
  .market-bar {
    width: 100%;
  }

  .market-metrics {
    flex-grow: 1;
    justify-content: space-between;
    border: 0.5px solid $dark;
    border-radius: 5px;
    padding: 0rem;

    &-container {
      padding: 0.4rem 0.6rem;
      justify-content: space-between;
      width: 100%;
    }
    
    &-box {
      span {
        text-wrap: nowrap;
      }
    }

    &-value {
      display: flex;
      flex-direction: row;
      margin-bottom: 2px;

      h6 {
        text-wrap: nowrap;
      }
    }
    
    .btn-pag-metric:hover {
      background-color: $background;
    }
    
    h6 {
      font-size: 0.9rem;
    }

    .funding-dropdown {
      .dropdown-toggle {
        display: flex;
        flex-direction: row;
        align-items: center;
        border: 0.5px solid $dark;
        border-radius: 3px;
        padding: 1px 0.3rem;
        font-size: 0.7rem;
      }
      .dropdown-toggle::after {
        font-size: 0.85rem;
        margin-top: 2px;
        color: $light;
      }
      .dropdown-menu {
        position: absolute;
        width: 15rem;
      }
      .dropdown-item {
        padding: 3px 10px;
        span {
          font-size: 0.8rem;
        }
      }
    }
  }

  .market-metrics::-webkit-scrollbar {
    height: 5px;
  }

  .market-metrics::-webkit-scrollbar-track {
    background: transparent;
  }

  .market-info {
    align-self: stretch;
    padding: 0.1rem 0rem;

    .btn-market-info {
      height: 100%;
      border: 0.8px solid $purple;
      border-radius: 3px;
    }
    .btn-market-info:hover {
      background-color: $background
    }
    .market-info-wrapper {
      position: absolute;
      background-color: $background;
      border: 0.5px solid $darker;
      border-radius: 3px;
      margin-top: 2px;
      padding: 1rem;
      width: 48rem;
      margin-left: 0rem;
      z-index: 2;

      .info-row {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0.35rem 0.1rem;
        height: 2rem;
        border-bottom: 0.5px solid $darker;
      }

      a {
        font-size: 0.87rem;
      }
    }
  }

  .btn-market-info:disabled {
    background-color: $background !important;
    border: none;
  }

  .chart-info {
    margin-bottom: 0.5rem;
  }

  .oracle-prices {
    display: flex;
    flex-direction: row;
    margin-top: 0.5rem;
    padding: 0.5rem;

    &-item {
      display: flex;
      align-items: center;

      &.price {
        margin-left: 1rem;
      }
      .number {
        margin-left: 1px;
      }

      @media only screen and (max-width: 1200px) {
        p {
          font-size: 0.95rem;
        }
        .number {
          font-size: 0.95rem;
        }
      }
    }
  }

  @media only screen and (max-width: 1399px),
  (min-height: 600px) and (max-height: 790px) and (orientation: landscape) {
    padding: 1rem 0rem;
  }

  @media only screen and (min-width: 1051px) and (max-width: 1160px) {
    width: 110%;
  }

  @media only screen and (min-width: 851px) and (max-width: 1050px) {
    width: 120%;
  }

}

.trade-bottom {
  padding-bottom: 2rem;
}

.exchange {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 0rem;
  background-color: $background;
  // border: 0.7px solid $dark;
  border-radius: 5px;

  @media only screen and (min-width: 601px) and (max-width: 850px) {
    margin-top: 4rem;
    margin-left: 3rem;
    margin-right: 3rem;
  }

  .exchange-inner {
    border: 0.7px solid $dark;
    border-radius: 5px;
    background-color: #0d0d0d;
    -webkit-box-shadow: inset 0px 2px 15px 1px #a540f20b;
    -moz-box-shadow: inset 0px 2px 15px 1px #a540f20b;
    box-shadow: inset 0px 2px 15px 1px #a540f20b;

    .warning-orders {
      margin-top: 0.8rem;
    }
  }
  .exchange-options2 {
    padding: 0.5rem;
    padding-right: 0.75rem;

    .market-info-wrapper {
      position: relative;
      width: auto;
      padding: 0rem;
      border: 0.1rem 0.5rem;
    }
  }
  .nav-tabs {
    height: 2.3rem;
    .nav-item {
      display: flex;
      flex-direction: row;
      justify-content: center;
      width: 25%;
      align-items: center;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;

      .nav-link {
        width: 100%;
        font-size: 0.9rem;
      }
    }
  }
  .btn {
    width: 100%;
  }
  .btn.small {
    width: auto;
  } 

  .options {
    width: 100%;
    padding: 1.3rem 0rem 0.2rem 0rem;
  }
  
  .leverage-group {
    margin-top: 1rem;

    .leverage-header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-end;
    }

    .leverage-values {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;
    }
  }

  .liquidation {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 1rem;
  }

  .maintainance {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 1rem;
    &-right {
      display: flex;
      align-items: center;
      svg {
        margin-right: 7px;
      }
    }
  }

  .value-group {
    width: 100%;
    
    &-total {
      display: flex;
      justify-content: space-between;
    }

    &-detail {
      margin-top: 10px;
      &.hide {
        display: none;
      }

      padding-left: 0.5rem;
    }

    .value-item {
      display: flex;
      justify-content: space-between;
      margin-top: 3px;
      padding-left: 1rem;

      h6 {
        font-size: 0.9rem;
      }

      &-desc {
        display: flex;
        align-items: center;

        .btn {
          margin-left: 0.5rem;
        }
      }

      .number {
        font-size: 0.9rem;
      }
    }
  }
}

.positions {
  background-color: $background;
  padding: 1rem;
  border: 0.5px solid $darkest;
  border-radius: 5px;

  @media only screen and (min-width: 601px) and (max-width: 850px) {
    margin-left: 3rem;
    margin-right: 3rem;
  }

  .nav-tabs {
    .nav-link {
      width: 15rem;
    }
  }

  .empty-positions {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 1rem;
  }

  .spinner-container {
    display: flex;
    justify-content: center;
    padding: 1rem;

    .spinner {
      color: $purple;
    }
  }

  .table {
    tr.hide {
      display: none;
    }
    
    .col-status {
      width: 6rem;
      padding-left: 2rem;
    }
    
    .liquidation-price {
      .status {
        font-size: 0.85rem;
      }
    }

    .liquidating {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding-left: 0.5rem;

      .spinner-border {
        margin-right: 5px;
      }
    }

    td.col-status {
      span {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;

        .spinner-border {
          margin-right: 5px;
        }
      }
    }
  }

  .orders-table {
    .col-status {
      width: 9rem;
      padding-left: 4rem;
    }
  }

  .filters-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 1rem;
    margin-bottom: 2rem;
    padding: 1rem 0.5rem;
    border: 0.5px solid $darker;
    border-radius: 5px;

    .right, .left {
      display: flex;
      flex-direction: row;
      padding: 0rem 0.5rem;
    }

    .filter-item {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-right: 1.5rem;
    }

    .dropdown {
      margin-left: 0.5rem;

      &-toggle-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        svg, img {
          margin-right: 7px;
        }
      }

      &-item {
        display: flex;
        flex-direction: row;
        align-items: center;

        svg, img {
          margin-right: 7px;
        }
      }
    }

    .dropdown-toggle {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 8rem;
    }
  }

  .history-footer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 2rem;
    margin-bottom: 1rem;

    .btn {
      width: 10rem;
    }
  }
}

.positions-history {
  padding: 1rem 0.5rem;
  
  [class*="col-"] {
    display: flex;
    align-items: center;
    &.text-right {
      justify-content: right;
    }
  }

  &-header {
    padding: 0.5rem 0.5rem;
    padding-right: 4rem;
  }

  .position-row {
    padding-right: 2rem;
    width: 100%;
  }

  h6 {
    padding: 0px;
  }

  .number {
    padding: 0px;
  }

  .sub-positions {
    th {
      padding: 0.2rem 0.4rem;
      font-size: 12px;
      color: $light2;
    }
    tr {
      border-bottom: 0.5px dotted $darker;
    }
    thead {
      tr {
        border-bottom: 0.5px solid $darker;
      }
    }
    a {
      color: $off-white;
      font-size: 0.85rem;
    }
    .number {
      font-size: 0.85rem;
    }
    .small {
      font-size: 0.7rem;
    }
  }

  .btn-more {
    width: 12rem;
  }
}


.pl-version-chooser {
  width: auto;

  .btn {
    padding: 3px 2px;
    font-size: 16px;
    width: 7rem;
  }
}

.liquidity {
  display: flex;
  justify-content: center;

  .cryptex-switch {
    position: absolute !important;
  }

  .nav-tabs {
    .nav {
      &-link {
        width: 16rem;
      }
    }
  }

  .version-chooser {
    display: flex;
    justify-content: flex-end;
    margin-top: -1.2rem;
  }

  &.pro {
    display: flex;
    flex-direction: column;
    .nav-tabs {
      margin-bottom: 2px;
      .nav {
        &-link {
          width: auto;
        }
      }
    }

    .pl-version-chooser {
      // position: absolute;
      // right: 0px;
      // margin-right: 3rem;
      margin-top: 0.8rem;
    }

    .trade-module {
      .oracle-prices {
        margin-top: -1rem;
      }
    }

    .positions {
      .dropdown-toggle {
        border: 0.5px solid $light;
      }
    }
  }

  .dropdown {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }
  .dropdown-toggle {
    display: flex;
    align-items: center;
    border: none;
    width: 100%;

    .market-desc {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin-left: 10px;
    }
  }
  .dropdown-item {
    display: flex;
    align-items: center;
  }
  .vaults-dropdown {
    border: 0.5px solid $light;
    border-radius: 5px;

    .dropdown-item {
      padding: 0.5rem 0.7rem;
      height: 3rem;
      border-bottom: 0.5px solid $darkest;

      .hstack {
        align-self: auto;
      }

      .right {
        align-items: flex-end;
        justify-content: center;
      }
    }

    .market-desc {
      .title {
        font-size: 17px;
      }
      .caption {
        font-size: 12px;
      }
    }

    .dropdown-toggle-container {
      display: flex;
      align-items: center;
      width: 100%;
      
      .vault-assets {
        margin-right: 5px;
      }
    }

    .toggle-section {
      display: flex;
      flex-direction: row;
      align-items: center;      
    }
    .desc-box {
      border-right: 0.5px solid $dark;
      padding-right: 1.5rem;
    }
    .apr-box {
      justify-content: center;
      margin-left: 0.25rem;
      margin-right: 0rem;
      width: 100%;
      .number {
        font-size: 1.4rem;
      }
    }

    .vertical-divisor {
      margin: 2px 0px;
    }

    .dropdown-menu-header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      border-bottom: 1px solid #281e31;
      margin: 0rem;
      padding: 0.2rem 0.2rem;

      span {
        width: 50%;
      }
    }
  }

  &-card {
    display: flex;
    flex-direction: column;
    padding: 1rem;
    border: 1px solid $light;
    border-radius: 5px;
    margin-top: 1.5rem;
    width: 55rem;

    &-header {
      margin-bottom: 1rem;

      h3 {
        margin-bottom: 0.5rem;
      }
    }

    &-body {
      margin: 1rem 0rem;

      .nav-tabs {
        width: 100%;

        /* .nav-item {
          width: 10rem;
        } */
      }

      .liquidity-provide, .liquidity-rewards {
        display: flex;
        padding-top: 0.5rem;
      }
      .liquidity-spinner {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 5rem 1rem;
      }
    }

    .body-left {
     width: 50%;
      margin-right: 0.5rem;

      .options.provide {
        margin-top: 1.5rem;
      }

      .deposit-button {
        width: 100%;
        margin-top: 1.5rem;

        &.rewards {
          margin-top: 1rem;
          margin-bottom: 1rem;
        }
      }

      &.rewards-v2{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }

      .rewards-exit {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
        padding: 0rem;
      }
    }

    .body-right {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 50%;
      padding: 1rem;
      margin-left: 0.5rem;
      border: 0.5px solid $dark;
      border-radius: 5px;
      background-color: $black;

      .number {
        padding-right: 0rem;
      }

      .value-item {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin: 0.5rem 0rem;

        .icon-item {
          display: flex;
          flex-direction: row;
          align-items: center;

          .number {
            margin-right: 10px;
          }
        }
      }

      .value-group {
        .value-item-desc {
          display: flex;
          align-items: center;

          .btn {
            margin-left: 0.5rem;
          }
        }

        &-detail {
          &.hide {
            display: none;
          }
          padding-left: 0.5rem;
        }
      }
      
      .values-bottom {
        .btn-primary {
          width: 100%;
        }
      }

      &.rewards-v2 {
        .btn-claim {
          margin-top: 2.5rem;
        }
      }      
    }
  }

  .claim-container {
    margin-top: 2rem;
    &-value {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 0.5rem;
      border: 0.5px solid $lighter;
      border-radius: 5px;
    }
    &-msg {
      display: flex;
      padding: 0.7rem 0.1rem;
      p {
        font-size: 14px;
      }
    }
  }
}

.menu {
  display: flex;
  flex-direction: row;
  align-items: center;
  z-index: 5;
  .nav {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 2rem;
    @media only screen and (max-width: 500px) {
      display: none;
    }

    .mt-4 {
      margin-top: 0rem !important;
      margin-bottom: 0rem !important;
    }

    &-item {
      padding: 0rem 0rem;
      .active {
        // filter: brightness(1118%);
        color: $white;
        text-shadow: 0px 0px 10px rgba(223, 33, 254);
        transform: scale(1.1);
      }
      .title {
        font-size: 1.2rem;
      }

      a {
        color: $lighter;
        text-decoration: none;
        font-weight: 500;

        &.opaque {
          color: $light;
        }
      }

      &.dropdown {
        .dropdown-toggle {
          border: none;
          font-size: 1.1rem;
        }
        .dropdown-toggle:after {
          margin-top: 3px;
        }
      }
      .dropdown-menu {
        .dropdown-item {
          font-size: 1.1rem;
        }
      }
    }

    &-item:hover { 
      filter: brightness(90%);
    }

    &-link {
      display: flex;
      align-items: center;
      width: 100%;
      height: 100%;      
    }
  }

  .dropdown-menu {
    border-bottom: 0.5px solid $darkest;
    border-left: 0.5px solid $darkest;
    border-right: 0.5px solid $darkest;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    width: 17rem;
  }
}

.fees {
  display: flex;
  flex-direction: column;
  margin-top: 0.2rem;
  margin-bottom: 1.4rem;

  &-total {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    &-desc {
      display: flex;
      flex-direction: row;
      align-items: center;

      .btn {
        font-size: 14.5px;
        font-weight: 600;
        padding: 0rem 7px;
        text-decoration: underline;
      }
      .btn:active { 
        background-color: transparent;
      }
    }
    &-amount {
      display: flex;
      align-items: center;

      svg {
        margin-right: 5px;
      }
    }
  }
  &-container {
    
    .row-value {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding-left: 0px;
      margin-top: 0.5rem;
    }

    &.hide {
      display: none;
    }
  }
}

.rewards {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem 10rem;

  @media only screen and (max-width: 1200px) { 
    padding: 2rem 5rem;
  }
  @media only screen and (max-width: 1100px) {
    padding: 2rem;    
  }
 
  .nav-item {
    width: 30%;
    .nav-link {
      width: 100%;
    }
  }

  .vstack {
    align-self: auto;
  }

  .rewards-container {
    width: 100%;
  }

  .reward-seasons {
    width: 100%;
    margin-bottom: 2rem;

    .seasons-info {
      justify-content: space-between;
    }
    .seasons-pagination {
      justify-content: space-between;
      margin: 0rem 1rem;
      background-color: $black;
    }    
  }

  .rewards-data {
    background-color: $background;
  }

  .reward-info-box, .reward-stat-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 0.5px solid #494866;
    background-color: #0f0f0f;
    border-radius: 5px;
    margin: 0.5rem 1rem;
    padding: 1rem 0.5rem;
    height: 7.5rem;
    width: 10rem;

    &.btn {
      border: 1px solid $purple;
      font-size: 1.5rem;
    }

    .row-inner {
      display: flex;
      align-items: center;
    }

    .date {
      font-size: 1.1rem;
    }

    .number {
      font-size: 1.2rem;
    }
  }

  .reward-stat-box {
    height: 5.3rem;
    margin: 0.5rem 0rem;

    .total {
      font-size: 1.5rem;
    }
  }

  .rewards-card {
    display: grid;
    grid-template-columns: 50% 50%;
    gap: 1rem;
    padding: 1rem;

    .reward-box {
      margin: 0.7rem 0rem;
      // background-color: #0f0f0f;
      // padding: 0.7rem;
    }
    
    .right {
      padding: 1rem;
    }

    .bordered {
      border: 0.5px solid #494866;
      border-radius: 5px;
    }

    .total-rewards-box {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      border: 0.5px solid #494866;
      border-radius: 5px;
      padding: 0.5rem;
      background-color: #0f0f0f;

      .total-rewards-inner {
        display: flex;
        align-items: center;
      }
    }
  }
}

.tradingview-widget-container {
  height: 90%;
  margin-top: 0.5rem;

  #tradingview_14a8d {
    height: 100%;
    width: 100%;
  }
}

.tradingview-widget-container-mobile {
  height: 60vh;

  #tradingview_14a8d {
    height: 100%;
    width: 100%;
  }
}

@media only screen and (min-width: 1051px) and (max-width: 1160px) {
  .trade-bottom {
    width: 110%;
  }
}

@media only screen and (min-width: 851px) and (max-width: 1050px) {
  .trade-bottom {
    width: 120%;
  }
}

@media only screen and (max-width: 767px){
  .login-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 1rem;
  }
  .contaner-login-item{
    width: 100%;
    margin-right: 0rem;
  }
  .social-media-buttons{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 1rem 2rem 1rem 0rem;
  }
  .sm-button{
    padding: 1rem;
    border-radius: 0px;
    border: 1px solid $purple;
    width: 100%;
    display: inline-flex;
    justify-content: center;
  }
  .scailing-icon{
    width: 100%;
  }
  .twitter-button{
    border: 1px solid $purple;
    color: $white;
  }
  .twitter-button:hover{
    background-color: $purple;
  }
  .action-button{
    width: 100%;
    margin-top: 1rem;
  }
}

@media only screen and (min-width: 768px) {
  .login-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 1rem;
  }
  .container-background-video{
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background: $black;
  }
  .video-object{
    width: 100%;
    height: 100%;
    position: absolute;
    object-fit: cover;
    z-index: 0;
  }
  .transparent-background{
    background-color: transparent !important;
  }
  .float-above-bg{
    z-index: 2;
    position: relative;
  }
  .container-login-item{
    width: 50%;
    margin-right: 0.5rem;
  }
  .social-media-buttons{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 1rem 20rem 1rem 0rem;
  }
  .sm-button{
    padding: 1rem;
    border-radius: 5px;
    border: 2px solid $highlight;
    width: 20%;
    display: inline-flex;
    justify-content: center;
  }
  .sm-button:hover{
    background-color: $highlight;
  }
  .scailing-icon{
    width: 100%;
  }
  .twitter-button{
    border: 1px solid $purple;
    border-radius: 5px;
    padding: 0.5rem;
    margin-right: auto;
    margin-left: auto;
    color: $white;
  }
  .twitter-button:hover{
    background-color: $purple;
    color: $white;
  }
}

@media only screen and (max-width: 600px) {
  .mobile-tabs {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 1rem;
    width: 100%;
    margin-top: 2rem;
  }
}

.terms-of-service{
  height: 300px;
  overflow-y: scroll;
  padding: 0.5rem;
  border: 1px solid $lighter;
  border-radius: 5px;
  margin-top: 1rem;
  font-size: 12px;
  line-height: 1.5;
  text-align: justify;
  text-justify: inter-word;
}


.terms-of-service h5{
  text-align: center;
}

.terms-of-service p {
  margin-top: 0.5rem;
  margin-bottom: 0.8rem;
}

.terms-of-service h3{
  text-align: center;
}

.terms-of-service::-webkit-scrollbar{
  width: 8px;
}

.terms-of-service::-webkit-scrollbar-track{
  background: transparent;
}
.terms-of-service::-webkit-scrollbar-thumb{
  background: $purple;
  border-radius: 20px;
  border: 3px solid $purple;
}

.mobile-table{
  width: 100%;
  max-width: 100%;
  overflow-x: scroll;
  border: 0.7px solid #494866;
  border-radius: 5px;
  padding: 0.5rem;
}

.mobile-table::-webkit-scrollbar{
  width: 1px !important;
}

.mobile-table::-webkit-scrollbar-track{
  background: transparent !important;
}
.mobile-table::-webkit-scrollbar-thumb{
  background: transparent !important;
  border-radius: 20px !important;
  border: 3px solid transparent !important;
}

.geoblock-container {
  margin-top: 1.5rem;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #7d078520;
}

::-webkit-scrollbar-track:hover{
  background: #7d078520;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #555;
  border-radius: 1rem;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: $purple;
}

#oracle-chart-tabs-tab-tooltip:hover{
  border-bottom: none !important;
}

@media (min-height: 600px) and (max-height: 800px) and (orientation: landscape) {
  .exchange {
    .exchange-inner.fixed-height {
      height: 660px;
      overflow-y: scroll;
    }
  }
}

@media (min-width: 601px) and (max-width: 1399px),
  (min-height: 600px) and (max-height: 790px) and (orientation: landscape)
{
  
  .navbar, .navbar-dark {
    padding-top: 0.4rem;
    padding-bottom: 0.7rem;

    .container-fluid {
      padding: 0rem;
    }
  }
  
  .trade-module {
    padding-top: 0.1rem;
    padding-bottom: 0.1rem;
    border: none;

    .trade-top {
      margin-bottom: 0.2rem;
    }
    .trade-left {
      width: 68%;
    }
    .trade-right {
      width: 32%;
    }

    .market-metrics {
      overflow-x: scroll;
      overflow-y: clip;

      /* &-container {
        width: auto;
      } */

      &-box {
        width: max-content;
      }
    }

    .market-info {
      .btn-market-info {
        border: 0.8px solid $purple;
        border-radius: 3px;
        padding: 0.1rem 0.5rem;
        width: 9rem;
      }
    }
  }

  .tradingview-widget-container {
    height: 90%;
  }

  .asset-input-group {
    margin-top: 0.5rem;    

    .input-group {
      padding-top: 0.4rem;
      padding-bottom: 0.4rem;

      &.index {
        padding-top: 0.3rem;
        padding-bottom: 0.3rem;
        align-items: center;
      }
    }

    .asset-balance {
      margin-top: 0.3rem;
    }
  }

  .exchange {
    .options {
      padding-top: 0.6rem;
    }

    .liquidation {
      margin-top: 0.5rem;
    }

    .leverage-group {
      margin-top: 0.7rem  
    }
  }

  .fees {
    margin-bottom: 0.8rem;

    .row-value {
      margin-top: 0.3rem;
    }

    .bold {
      font-size: 0.9rem;
      font-weight: 600;      
    }

    .btn-secondary {
      font-size: 0.9rem;
    }
  }
}

@media (min-width: 601px) and (max-width: 1299px) {
  .trade-module {
    .market-metrics {
        overflow-x: scroll;
        // overflow-y: clip;
    
      &-container {
          width: auto;
      }
    }
  }
}

@import "./components";
@import "./lifi";
@import "./charts";
@import "./governance";
@import "./nosupport";
@import "./mobile";